@import 'main.scss';
.counter {
  position: fixed;
  right: 40px;
  bottom: 40px;
  display: flex;
  height: 64px;
  font-size: 64px;
  line-height: 66px;
  clip-path: polygon(0 0, 100% 0, 100% 64px, 0 64px);
  color: var(--white);
  font-family: var(--font-avant);
  font-weight: 700;

  @media screen and (min-width: 1024px) {
    height: 129px;
    font-size: 129px;
    line-height: 131px;
    clip-path: polygon(0 0, 100% 0, 100% 129px, 0 129px);
    gap: 16px;
  }

  .counterOne,
  .counterTwo,
  .counterThree {
    position: relative;
  }

  .numOneOffset {
    position: relative;
    right: -8px;

    @media screen and (min-width: 1024px) {
      right: -16px;
    }
  }

  .numTwoOffset {
    position: relative;
    right: -5px;

    @media screen and (min-width: 1024px) {
      right: -10px;
    }
  }

  .num {
    -webkit-text-stroke: 2.7px white;
  }

  .numOutline {
    color: transparent;
    -webkit-text-stroke: 2.7px white;
  }
}
